import React from 'react';
import { Messege } from '../Messege';

export const ResumeSocial = () => {
  return (
    <div className="social-page">
      <Messege />
    </div>
  );
};
