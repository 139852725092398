import React from 'react';
import { ImgComp } from '../Img';

const CoderLogo = () => {
  return (
    <div className="empty">
      <div className="empty-icon">
        <ImgComp src="/preety.png" alt="preety" />
      </div>
      <h1 className="empty-title">Preety Kumari</h1>
      <nav>
        <a className="btn btn-link" href="mailto:preetycom.485@gmail.com">
          Mail: preetycom.485@gmail.com
        </a>
        <a className="btn btn-link" href="tel:+918578079802">
          <p>Mob: +91-8578079802</p>
        </a>
      </nav>
      <nav className="btn-group">
        <a
          className="btn btn-success"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/preety-me/"
        >
          LinkedIn
        </a>
        <a className="btn btn-success" href="/about">
          Profile Page
        </a>
        <a className="btn btn-success" href="/connect">
          Contact Me
        </a>
      </nav>
    </div>
  );
};

export { CoderLogo };
