
export const IMAGE_APPROACH_OPTIONS = {
 "BASE64": "/base64/",
 "BINARY_IMAGE": "/"
};
export const buildFlags = {
 "TEST_MESSAGE_THROW": false,
 "isOnlyImageNameOrContentChanged": false,
 "IMAGE_OPTIMIZATION": true,
 "ASSETS_CACHING": true,
 "ANALYSE_BLOG": true,
 "MINIFY_JS": true,
 "CREATE_KEY_WORD_TEXT_FILE": true,
 "isChatPaid": false,
 "IMAGE_APPROACH": "/"
};
