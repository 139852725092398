
export const feedHtml = `
<div class="social-links-list">
  
<div class="public-link">
    <a
      href="https://www.facebook.com/akhilesh.app4pc/"
      class="social-links fb"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/fb.png" alt="fb" />
    </a>
    <a
      href="https://www.instagram.com/trychaos/"
      class="social-links insta"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/insta.png" alt="insta" />
    </a>
    <a
      href="https://twitter.com/akhileshcoder"
      class="social-links twitter"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/tweeter.png" alt="tweeter" />
    </a>
    <a href="https://wa.me/8585965748" class="social-links whatsapp" target="_blank" rel="noopener noreferrer">
      <img src="/whatsapp.png" alt="whatsapp" />
    </a>
    <a
      href="https://www.linkedin.com/in/akhileshcoder"
      class="social-links linkedin"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/linkedin.png" alt="linkedin" />
    </a>
  </div>
  <div class="tech-link">
    <a
      href="https://stackoverflow.com/users/4655241/akhilesh-kumar"
      class="social-links fb"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/stackOverflow.png" alt="stackOverflow" />
    </a>
    <a
      href="https://www.quora.com/profile/Akhilesh-Kumar-217"
      class="social-links insta"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/quora.png" alt="quora" />
    </a>
    <a href="https://github.com/app4pc" class="social-links twitter" target="_blank" rel="noopener noreferrer">
      <img src="/github.png" alt="git" />
    </a>
    <a
      href="https://gitlab.com/trychaos_code"
      class="social-links twitter"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/gitlab.png" alt="git" />
    </a>
    <a
      href="https://www.npmjs.com/package/web-project-builder"
      class="social-links whatsapp"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/npm1.png" alt="npm" />
    </a>
    <a
      href="https://www.npmjs.com/package/y2j"
      class="social-links whatsapp"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/npm2.png" alt="npm" />
    </a>
    <a
      href="https://discourse.wicg.io/u/Akhilesh_Kumar"
      class="social-links linkedin"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/w3c.png" alt="w3c" />
    </a>
  </div>
  <div class="personal-link">
    <a href="mailto:akhileshcoder@gmail.com" class="social-links fb" target="_blank" rel="noopener noreferrer">
      <img src="/mail.png" alt="mail" />
    </a>
    <a href="tel:+918585965748" class="social-links insta" target="_blank" rel="noopener noreferrer">
      <img src="/phone.png" alt="phone" />
    </a>
    <a
      href="http://m.me/akhilesh.app4pc"
      class="social-links twitter"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/messenger.png" alt="messenger" />
    </a>
    <a href="https://t.me/jaiChandal" class="social-links twitter" target="_blank" rel="noopener noreferrer">
      <img src="/telegram.png" alt="telegram" />
    </a>
  </div>

 
<div class="columns social-links-video-feeds">
    <h1 class="column col-12">My Random Video Universe</h1>
   
    <iframe
      title="akhilesh facebook feed"
      class="column col-12"
      src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fakhilesh.app4pc%2Fvideos%2F1753766954895046%2F&show_text=false&width=560&t=0"
      style="border: none; overflow: hidden; "
      scrolling="no"
      frameBorder="0"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
    <iframe
      class="column col-12"
      src="https://www.youtube.com/embed/GvyvNj7qyOA"
      title="prabhu to kalyug"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
    
    <blockquote
              class="column col-4 col-sm-6 col-xs-12 instagram-media"
              data-instgrm-permalink="https://www.instagram.com/reel/Cf7UuSiK5L2/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
              data-instgrm-version="14"
            ></blockquote>,<blockquote
              class="column col-4 col-sm-6 col-xs-12 instagram-media"
              data-instgrm-permalink="https://www.instagram.com/reel/CjIviODDCiJ/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
              data-instgrm-version="14"
            ></blockquote>,<blockquote
              class="column col-4 col-sm-6 col-xs-12 instagram-media"
              data-instgrm-permalink="https://www.instagram.com/reel/CsRheQWACF6/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
              data-instgrm-version="14"
            ></blockquote>,<blockquote
              class="column col-4 col-sm-6 col-xs-12 instagram-media"
              data-instgrm-permalink="https://www.instagram.com/reel/CsRfzrvOXgy/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
              data-instgrm-version="14"
            ></blockquote>,<blockquote
              class="column col-4 col-sm-6 col-xs-12 instagram-media"
              data-instgrm-permalink="https://www.instagram.com/tv/CndcfxZD0Rb/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
              data-instgrm-version="14"
            ></blockquote>,<blockquote
              class="column col-4 col-sm-6 col-xs-12 instagram-media"
              data-instgrm-permalink="https://www.instagram.com/reel/Cl1oV64Djx3/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
              data-instgrm-version="14"
            ></blockquote>,<blockquote
              class="column col-4 col-sm-6 col-xs-12 instagram-media"
              data-instgrm-permalink="https://www.instagram.com/reel/Cj5XYdajnIC/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
              data-instgrm-version="14"
            ></blockquote>,<blockquote
              class="column col-4 col-sm-6 col-xs-12 instagram-media"
              data-instgrm-permalink="https://www.instagram.com/tv/CjLyO6njSGy/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
              data-instgrm-version="14"
            ></blockquote>
  </div>
</div>
`;
export const clientRoutes = {
 "/main": {
  "logo": "/preety.png",
  "k": "/main",
  "title": "Welcome to Preety Kumari's World - Developer & Innovator"
 },
 "/about": {
  "logo": "/preety.png",
  "k": "/about",
  "title": "About Preety Kumari - Professional Journey & Expertise"
 },
 "/connect": {
  "logo": "/preety.png",
  "k": "/connect",
  "title": "Reach Out to Preety Kumari - Open Lines for Communication & Queries"
 }
};
