const resData = {
  name: 'Preety Kumari',
  eMail: 'preetycom.485@gmail.com',
  Phone: '+91 8578079802',
  Address: 'Marathahalli, Bengaluru, IN',
  Summary:
    'Experienced web and application developer, with 5+ years of practical experience with cutting-edge web development technologies.' +
    ' Skilled in developing projects from the ground up to full deployment, utilizing a wide range of tech stacks. I am an effective' +
    ' communicator with exceptional organizational abilities. My expertise lies in creating efficient, well-structured, and maintainable code.',
  'Profile Summary': {
    'Front-End Proficiency':
      'Specialized in leveraging HTML, CSS, and JavaScript, with advanced expertise in frameworks and libraries such' +
      ' as jQuery, AngularJS, and ReactJS for dynamic and responsive web development',
    'Back-End Expertise':
      'Proficient in NodeJS and ExpressJS for server-side logic, MongoDB for database management, and AWS for cloud' +
      ' services integration, ensuring robust back-end support.',
    'UI Leadership':
      'Directed the User Interface (UI) design and implementation for two major teams, Global UI and Platform,' +
      ' demonstrating strong leadership in visual and interaction design',
    'Development & Innovation':
      'Proven track record in pioneering new features from concept to deployment, showcasing the ability to' +
      ' innovate and execute complex functionalities',
    'Code Optimization':
      'Skilled in refactoring, restructuring, and enhancing the existing codebase for improved efficiency, readability, and performance.',
    'Performance Engineering':
      ' Expert in optimizing web performance and ensuring front-end scalability to handle increasing loads and user demands seamlessly',
    'Collaborative Engineering':
      'Experienced in collaborating with cross-functional teams for system design, integration, and ongoing application maintenance, ensuring cohesive and scalable system architecture.',
    'Rapid Technology Adaptation':
      'Demonstrates a keen ability to quickly assimilate new technologies and frameworks, staying at the forefront of web development trends and practices.',
  },
  'Key Skills': {
    'Front-End Development': {
      'Languages & Frameworks': 'React.js, JavaScript, AngularJS, TypeScript, HTML5, CSS, jQuery, AJAX',
      'Tools & Libraries': 'Webpack, JSON, XML',
    },
    'Back-End Development': {
      'Languages & Frameworks': 'Node.js, Express, Groovy',
      Databases: ' MongoDB, MySQL, Oracle, PostgreSQL',
    },
    'DevOps & CI/CD': {
      'Containerization & Orchestration': 'Docker, Kubernetes',
      'Integration & Deployment': 'Jenkins, GIT, JIRA, Shell Scripting',
    },
    'Cloud Computing & Web Servers': {
      'Platforms & Services': 'AWS (Amazon Web Services), Cloud Computing',
      'Web Servers': 'Nginx',
    },
  },
  'Work Experience': {
    'May, 2021 - Present': 'UI Developer, MoEngage',
    'Mar, 2018 - Apr, 2021': 'Fullstack Developer, Freelancer',
  },
  Projects: {
    'Academic projects': {
      'Time Taken': '4 Months',
      detailsPre:
        'Online chatting service, Attendance management system,' +
        'College Game event precipitation win streak management system,' +
        'Some personal POC in: https://github.com/prebyte',
    },
    'Customer Satisfaction Analysis Tool / Rating Stat Charting': {
      'Time Taken': '2 Weeks',
      detailsPre:
        "<b>Objective:</b> Led the development of the 'Rating Stats Charting' feature at MoEngage to capture customer satisfaction levels.<br>" +
        '<b>Tools Used:</b> Utilized HTML, CSS, JavaScript, React, and Highcharts for efficient and interactive data visualization.<br>' +
        '<b>Outcome:</b> Delivered the project on time with high quality, facilitating improved understanding of customer preferences and product performance.<br>',
    },
    'A/B Testing Enhancement for OSM/WebP Campaigns': {
      'Time Taken': '3 Weeks',
      detailsPre:
        '<b>Objective:</b> Implemented an advanced feature supporting A/B testing within OSM/WebP campaigns, aimed at optimizing user engagement and conversion rates.<br>' +
        '<b>Key Contributions:</b> Designed and integrated a robust A/B testing framework that allows for systematic comparison of campaign variations, leveraging statistical analysis to determine optimal content strategies. <br>' +
        '<b>Technologies Used:</b> Developed using cutting-edge web technologies, including HTML5, CSS3, JavaScript, and React, ensuring seamless integration and high performance across platforms.<br>' +
        '<b>Outcome:</b> The feature significantly improved campaign performance metrics by enabling data-driven decision-making, thereby enhancing the overall effectiveness of marketing strategies and user experience.',
    },
  },
  Certification: [
    'React - The Complete Guide (incl Hooks, React Router, Redux)',
    "Microfrontends with React: A Complete Developer's Guide",
    'MongoDB - The Complete Developers Guide 2022',
    'Ultimate AWS Certified Solutions Architect Associate 2022',
    'Ultimate AWS Certified Developer Associate 2022',
    'Ultimate AWS Certified Cloud Practitioner – 2022',
    'Jenkins From Zero To Hero: Become a DevOps Jenkins Master',
    'Webpack 5: The Complete Guide For Beginners',
    'Understanding TypeScript - 2022 Edition',
    'The Complete Apache Groovy Developer Course',
    'React Testing with Jest and Enzyme',
    'Docker Mastery: with Kubernetes +Swarm from a Docker Captain',
    'NGINX Fundamentals: High Performance Servers from Scratch',
    'Microservices with Node JS and React',
    'Linux Mastery: Master the Linux Command Line in 11.5 Hours',
    '100 Days of Code: The Complete Python Pro Bootcamp for 2022',
  ],
};

export { resData };
