import jQuery from 'jquery';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { API_METHOD, API_STATE, API_URL, formFieldsInMsg, formTypes, getApi, getPreviousPages } from '../utils';
import { IWindow } from '../utils/types';
import { Checkbox } from './common/checkbox';
import { isRichTextEditorSupported, RichTextEditor } from './common/editor/RichTextEditor';

interface IFrom {
  email?: string;
  gender?: string;
  message?: string;
  mob?: string;
  name?: string;
  subscribed?: boolean;
}

export const Messege = () => {
  const [formData, setFormData] = useState({} as IFrom);
  const [dataState, setDataState] = useState(API_STATE.PENDING);

  const setData = (name, e) => {
    setFormData({
      ...formData,
      [name]: typeof e === 'string' ? e : e.target?.value,
    });
  };
  const getIP = async () => {
    let det: any = {};
    try {
      det = await fetch(API_URL.IP_DET);
      det = await det.json();
    } catch (e: any) {
      console.log(`API: ${API_URL.IP_DET}, err: ${e?.message}`);
    }
    return det?.ip;
  };
  const getIPMeta = async (ip: string) => {
    let meta = { ip };
    try {
      meta = await new Promise((resolve, reject) => {
        jQuery.getJSON(`https://ipapi.co/${ip}/json`, async data => {
          resolve(data || {});
        });
      });
    } catch (e: any) {
      console.log(`API: ${API_URL.IP_DET}, err: ${e?.message}`);
    }
    return meta;
  };
  const sendData = async () => {
    setDataState(API_STATE.SENDING);
    if (Object.values(formData).length > 2) {
      const meta = await getIPMeta(await getIP());
      const { message } = await getApi(API_URL.MESSAGE, {
        method: API_METHOD.POST,
        body: JSON.stringify({
          data: formData,
          meta: {
            ...meta,
            ...((window as unknown as IWindow).cd.meta || {}),
          },
        }),
      });
      message && toast(message);
      setDataState(API_STATE.DONE);
    } else {
      setDataState(API_STATE.PENDING);
    }
  };
  const getFormElms = () => {
    const elms = [...formFieldsInMsg];
    const prevPages = getPreviousPages();
    prevPages && elms.push(prevPages);
    return elms.map(({ label, k, id, type, placeholder, opt, rows, checkFlags }, index) => {
      if (type === formTypes.TEXT) {
        return (
          <div className="form-group" key={index}>
            <label className="form-label" htmlFor={id}>
              {label}
            </label>
            <input className="form-input" type="text" id={id} placeholder={placeholder} onKeyUp={e => setData(k, e)} />
          </div>
        );
      } else if (type === formTypes.RADIO) {
        return (
          <div className="form-group" key={index}>
            <div className="form-label">{label}</div>
            {opt?.map((o, ind) => (
              <label className="form-radio" key={ind}>
                <input type="radio" name="gender" className="disp-n" onChange={e => setData(k, o.k)} />
                <i className="form-icon"></i> {o.l}
              </label>
            ))}
          </div>
        );
      } else if (type === formTypes.TEXTAREA) {
        const txtAreaProp: any = {
          className: 'form-input',
          id,
          placeholder,
          rows,
          onChange: (e: any) => setData(k, e),
        };
        if (!isRichTextEditorSupported) {
          txtAreaProp.onKeyUp = txtAreaProp.onChange;
          delete txtAreaProp.onChange;
        }
        return (
          <div className="form-group" key={index}>
            <label className="form-label" htmlFor={id}>
              {label}
            </label>
            {isRichTextEditorSupported ? <RichTextEditor {...txtAreaProp} /> : <textarea {...txtAreaProp} />}
          </div>
        );
      } else if (type === formTypes.CHECKBOX) {
        return (
          <div className="form-group" key={index}>
            <Checkbox label={label} onChange={d => setData(k, d ? checkFlags?.t : checkFlags?.f)} />
          </div>
        );
      } else if (type === formTypes.SELECT) {
        return (
          <div className="form-group" key={index}>
            <label className="form-label" htmlFor={id}>
              {label}
            </label>
            <select id={id} name={k} className="form-select" onChange={e => setData(k, e)}>
              {opt?.map((o, ind) => (
                <option key={ind} value={o.k}>
                  {o.l}
                </option>
              ))}
            </select>
          </div>
        );
      }
      return null;
    });
  };
  return (
    <div className="message-form">
      <h2>Reach Out to Us!</h2>
      {getFormElms()}
      <div className="form-group">
        <button className="btn btn-primary submit-btn" onClick={sendData} disabled={dataState !== API_STATE.PENDING}>
          {dataState === API_STATE.PENDING ? `Send` : dataState === API_STATE.SENDING ? 'Sending..' : 'Stay Connected'}
        </button>
      </div>
    </div>
  );
};
