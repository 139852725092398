import React from 'react';

import { ToastContainer } from 'react-toastify';
import { CoderLogo } from './CoderLogo';
import { ResumeDetails } from './Details';
import { ResumeSocial } from './ResumeSocial';

export enum ResumePages {
  MAIN = 'MAIN',
  ABOUT = 'ABOUT',
  CONNECT = 'CONNECT',
}

interface IResume {
  type?: ResumePages;
}

const getPages = (type: ResumePages) => {
  switch (type) {
    case ResumePages.MAIN:
      return <CoderLogo />;
    case ResumePages.ABOUT:
      return <ResumeDetails />;
    case ResumePages.CONNECT:
      return <ResumeSocial />;
    default:
      return <CoderLogo />;
  }
};

export const Resume = ({ type = ResumePages.MAIN }: IResume) => {
  return (
    <div className="App">
      <div className="page">
        <div className="page-area">{getPages(type)}</div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Resume;
