import React from 'react';

interface IImagePDFC {
  className: string;
  label?: string;
}

export const StaticPdf = ({ className, label }: IImagePDFC) => {
  return (
    <a className={className} target="_blank" href="/res/PreetyRes_s.pdf" download>
      {label}
    </a>
  );
};
